@font-face {
    font-family: "BBS";
    src: url("./assets/Balgin\ Black\ Sm\ Condensed.ttf") format("woff");
  }  

  
  html,
  body {
    margin: 0;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    /*overflow-scrolling: touch;*/
    box-sizing: border-box;
  }

  #contenedor-principal-total {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    background-color: #fa6640;
    
  }

  .img-icon{
    width: 250px;
    position: absolute;
    top: 0px;
    left: 0px;
  }

  .zona-1{
    width: 100%;
    height: 100vh;
    background-color: #fa6640;
  }


  .mover-texto-1{
    font-family: "BBS";
    opacity: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 400px;
    font-weight: normal;
  }

  
  .mover-texto-2{
    font-family: "BBS";
    opacity: 1;
    position: absolute;
    width: 100%;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 200px;
    font-weight: normal;
    -webkit-text-stroke: #fff 1.5px;
    color: transparent;
    /*z-index: 120;*/
  }


  .texto-deletreado{
    font-size: 160px;
    font-family: "BBS"; 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: #ffaeee;
  }

  .texto-deletreado-2{
    font-size: 160px;
    font-family: "BBS"; 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: #00b1ff;
  }

  .texto-deletreado-3{
    font-size: 160px;
    font-family: "BBS"; 
    position: absolute;
    top: 50%;
    left: 60%;
    transform: translate(-50%,-50%);
    color: #00b341;
  }

  .texto-deletreado-4{
    font-size: 160px;
    font-family: "BBS"; 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: #ff5627;
  }

  
  .texto-deletreado-5{
    font-size: 160px;
    font-family: "BBS"; 
    position: absolute;
    top: 50%;
    left: 45%;
    transform: translate(-50%,-50%);
    color: #52602b;
  }

  .texto-deletreado-6{
    font-size: 160px;
    font-family: "BBS"; 
    position: absolute;
    top: 50%;
    left: 65%;
    transform: translate(-50%,-50%);
    color: #003852;
  }

  .txt-de{
    transform: rotate(-90deg);
    position: fixed;
    font-size: 38rem;
    bottom:0px;
    left: -40px;
    font-family: 'BBS';
    line-height: 1em;
    -webkit-text-stroke: 4px #1d1d1d;
    font-weight: normal;
    color: transparent;
  }

  .txt-gente{
   
    position: fixed;
    font-size: 400px;
    top: -60px;
    left: 100px;
    font-family: 'BBS';
    line-height: 1em;
    -webkit-text-stroke: 4px #1d1d1d;
    font-weight: normal;
    color: transparent;
  }

  .bloque-corazon{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  
  .txt-compromiso-com{
    font-size: 170px;
    font-family: "BBS"; 
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: #fff;
    text-align: center;
    line-height: 1em;
  }

  .txt-convertirnos{
    font-size: 170px;
    font-family: "BBS"; 
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: #fff;
    text-align: center;
    line-height: 1em;
  }

  .fundido-verde{
    position: absolute;
    width: 100wh;
    height: 100wh;
    top: 0;
    left: 0;
  }

  /* PRUEBAS */

@media (max-width: 1600px) {

  .img-icon{
    width: 210px;
  }

  

  .mover-texto-1{
    font-size: 300px;
    font-weight: normal;
  }

  
  .mover-texto-2{
    font-size: 150px;
  }




  .txt-de{
    font-size: 28rem;
  }

  .txt-gente{
    font-size: 340px;
    left: 40px;
  }
}