@font-face {
  font-family: BBS;
  src: url("Balgin Black Sm Condensed.20eb4852.ttf") format("woff");
}

html, body {
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  height: 100%;
  margin: 0;
}

#contenedor-principal-total {
  background-color: #fa6640;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.img-icon {
  width: 250px;
  position: absolute;
  top: 0;
  left: 0;
}

.zona-1 {
  background-color: #fa6640;
  width: 100%;
  height: 100vh;
}

.mover-texto-1 {
  opacity: 1;
  font-family: BBS;
  font-size: 400px;
  font-weight: normal;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mover-texto-2 {
  opacity: 1;
  text-align: center;
  -webkit-text-stroke: #fff 1.5px;
  color: #0000;
  width: 100%;
  font-family: BBS;
  font-size: 200px;
  font-weight: normal;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.texto-deletreado {
  color: #ffaeee;
  font-family: BBS;
  font-size: 160px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.texto-deletreado-2 {
  color: #00b1ff;
  font-family: BBS;
  font-size: 160px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.texto-deletreado-3 {
  color: #00b341;
  font-family: BBS;
  font-size: 160px;
  position: absolute;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);
}

.texto-deletreado-4 {
  color: #ff5627;
  font-family: BBS;
  font-size: 160px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.texto-deletreado-5 {
  color: #52602b;
  font-family: BBS;
  font-size: 160px;
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translate(-50%, -50%);
}

.texto-deletreado-6 {
  color: #003852;
  font-family: BBS;
  font-size: 160px;
  position: absolute;
  top: 50%;
  left: 65%;
  transform: translate(-50%, -50%);
}

.txt-de {
  -webkit-text-stroke: 4px #1d1d1d;
  color: #0000;
  font-family: BBS;
  font-size: 38rem;
  font-weight: normal;
  line-height: 1em;
  position: fixed;
  bottom: 0;
  left: -40px;
  transform: rotate(-90deg);
}

.txt-gente {
  -webkit-text-stroke: 4px #1d1d1d;
  color: #0000;
  font-family: BBS;
  font-size: 400px;
  font-weight: normal;
  line-height: 1em;
  position: fixed;
  top: -60px;
  left: 100px;
}

.bloque-corazon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.txt-compromiso-com, .txt-convertirnos {
  color: #fff;
  text-align: center;
  width: 100%;
  font-family: BBS;
  font-size: 170px;
  line-height: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fundido-verde {
  width: 100wh;
  height: 100wh;
  position: absolute;
  top: 0;
  left: 0;
}

@media (width <= 1600px) {
  .img-icon {
    width: 210px;
  }

  .mover-texto-1 {
    font-size: 300px;
    font-weight: normal;
  }

  .mover-texto-2 {
    font-size: 150px;
  }

  .txt-de {
    font-size: 28rem;
  }

  .txt-gente {
    font-size: 340px;
    left: 40px;
  }
}

/*# sourceMappingURL=index.aeb5be19.css.map */
